import {h} from 'preact';
import styles from "./style.scss"

const NotFound = () => {
  return (
    <main id="main-container" className={styles.wrapper}>
      <div className={styles.main}>
        <div className={styles.block}>
          <div className={styles.blockNum}>
            <h1>4</h1>
            <img
              loading="lazy"
              decoding="async"
              src="/assets/components/404/Shape.webp" alt="404" title="404" />
            <h1>4</h1>
          </div>
          <div className={styles.blockBtn}>
            <p>Кажется такой страницы не существует</p>
            <button
              className={styles.navBtn}
              onClick={() => location.href = "/"}
            >
              На главную
            </button>
          </div>
        </div>
      </div>
    </main>
  );
};

export default NotFound;
