import {h} from 'preact';
import {Router} from 'preact-router';
import Home from '../routes/home';
import Header from './Header/Header';
import Footer from "./Footer/Footer";
import Cases from "../routes/projects";
import About from "../routes/team";
import Blog from "../routes/blog";
import Services from "../routes/services";
import Contacts from "../routes/contacts";
import ReputationDecisions from "../routes/reputation_decisions";
import StartUp from "../routes/start_ups";
import Optimization from "../routes/optimization";
import Loyalty from "../routes/loyalty";
import Sales from "../routes/sales";
import Competitiveness from "../routes/competitiveness";
import WebDev from "../routes/web-develop";
import MobileDev from "../routes/mobile-develop";
import Outstaff from "../routes/outstaff";
import TechnicalSupport from "../routes/technical_support";
import NoCode from "../routes/no-code";
import BoxSolution from "../routes/boxed_solutions";
import Design from "../routes/design";
import NotFound from "../routes/404/404";
import Success from "../routes/success";
import FinPro from "../routes/finpro";
import UkiePage from "../routes/ukie";
import Privacy from "../routes/privacy";
import Continental from "../routes/contenintal";
import RealEstate from "../routes/real_estate";
import FilaraCosmo from "../routes/filara";

const App = ({ changeLanguage }) => (
    <div id="app">
      <Header changeLanguage={changeLanguage} />
      <Router>
        <Home path="/" />
        <Cases path="/projects" />
        <About path="/team" />
        <Blog path="/blog" />
        <Services path="/services" />
        <Contacts path="/contacts" />
        <ReputationDecisions path="/reputation-decisions" />
        <StartUp path="/startups" />
        <Optimization path="/optimization" />
        <Loyalty path="/loyalty" />
        <Sales path="/sales" />
        <Competitiveness path="/competitiveness" />
        <WebDev path="/services/web-development" />
        <MobileDev path="/services/mobile-development" />
        <Outstaff path="/services/outstaff" />
        <TechnicalSupport path="/services/technical-support" />
        <NoCode path="/services/no-code" />
        <BoxSolution path="/services/boxed-solutions" />
        <Design path="/services/design" />
        <Success path="/success" />
        <FinPro path="/projects/crm/fin-pro" />
        <UkiePage path="/projects/web/ukie" />
        <Continental path="/projects/web/continental/" />
        <RealEstate path="/projects/crm/real-estate" />
        <FilaraCosmo path="/projects/mobile/filara" />
        <Privacy path="/privacy" />
        <NotFound path="/404" />
      </Router>
      <Footer />
    </div>
);

export default App;
